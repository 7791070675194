import revive_payload_client_db3LApv38s from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.9.0_eslint@8.57.0_sass@1.77.6_typescript@5.5.2_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ThFarqDXsA from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.9.0_eslint@8.57.0_sass@1.77.6_typescript@5.5.2_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YtXAB3vyqr from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.9.0_eslint@8.57.0_sass@1.77.6_typescript@5.5.2_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nmvhKJC9jj from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.9.0_eslint@8.57.0_sass@1.77.6_typescript@5.5.2_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ocQ7tlCxIN from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.9.0_eslint@8.57.0_sass@1.77.6_typescript@5.5.2_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_JxyJXZAFOr from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.9.0_eslint@8.57.0_sass@1.77.6_typescript@5.5.2_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pQk7y71ABU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.9.0_eslint@8.57.0_sass@1.77.6_typescript@5.5.2_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_h4cvXyVBTY from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.5.2_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/zulassung-digital/.nuxt/components.plugin.mjs";
import prefetch_client_ycKzcGLwML from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.9.0_eslint@8.57.0_sass@1.77.6_typescript@5.5.2_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_5zaPkBXscN from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_x2APDvTgmf from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_PV4QyjJTVL from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_SKlypSdItm from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_AuXlLGRAwe from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import feathers_GSPs2K4t3q from "/vercel/path0/layers/shop-api-feathers/plugins/feathers.ts";
import cookie_banner_client_gpu0cuWAIt from "/vercel/path0/layers/base/plugins/cookie-banner.client.ts";
import trpc_UmSBE3Wgkx from "/vercel/path0/layers/base/plugins/trpc.ts";
import event_directive_iS46eQdIJo from "/vercel/path0/layers/google-analytics/plugins/event-directive.ts";
import script_6zAVbHB04w from "/vercel/path0/layers/google-analytics/plugins/script.ts";
import hotjar_client_HJG8no80GI from "/vercel/path0/zulassung-digital/plugins/hotjar.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/zulassung-digital/plugins/sentry.client.ts";
export default [
  revive_payload_client_db3LApv38s,
  unhead_ThFarqDXsA,
  router_YtXAB3vyqr,
  payload_client_nmvhKJC9jj,
  navigation_repaint_client_ocQ7tlCxIN,
  check_outdated_build_client_JxyJXZAFOr,
  chunk_reload_client_pQk7y71ABU,
  plugin_vue3_h4cvXyVBTY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ycKzcGLwML,
  slideovers_5zaPkBXscN,
  modals_x2APDvTgmf,
  colors_PV4QyjJTVL,
  plugin_client_SKlypSdItm,
  plugin_AuXlLGRAwe,
  feathers_GSPs2K4t3q,
  cookie_banner_client_gpu0cuWAIt,
  trpc_UmSBE3Wgkx,
  event_directive_iS46eQdIJo,
  script_6zAVbHB04w,
  hotjar_client_HJG8no80GI,
  sentry_client_shVUlIjFLk
]