import { plateTypeOptions, vehicleOptions } from '../constants/options'

export default () => {
  const wkzSearchStore = useWkzSearchStore()
  const wkzSearchesStore = useWkzSearchesStore()

  const officeProxy = computed({
    get () {
      return wkzSearchStore.office
    },
    set (value) {
      wkzSearchStore.office = value
    }
  })

  const suggestionTypeProxy = computed({
    get () {
      return wkzSearchStore.suggestionType
    },
    set (value) {
      wkzSearchStore.suggestionType = value
    }
  })

  const tokenProxy = computed({
    get () {
      return wkzSearchStore.token
    },
    set (newToken) {
      newToken = String(newToken || '').toUpperCase()
  
      const isPlateTokenValid = officeProxy.value?.plateTokens.includes(newToken)
  
      if (!isPlateTokenValid) {
        const defaultToken = officeProxy.value?.plateTokens[0] as string
        wkzSearchStore.token = defaultToken
      } else {
        wkzSearchStore.token = newToken
      }
  
      adjustNumbers()
    }
  })

  const lettersProxy = computed({
    get () {
      return wkzSearchStore.letters
    },
    set (newLetters) {
      wkzSearchStore.letters = newLetters
    }
  })

  const numbersProxy = computed({
    get () {
      return wkzSearchStore.numbers
    },
    set (newNumbers) {
      wkzSearchStore.numbers = newNumbers
    }
  })

  const plateTypeProxy = computed({
    get () {
      return wkzSearchStore.plateType
    },
    set (newPlateType) {
      wkzSearchesStore.searches = []
      wkzSearchesStore.selectPlate(null, null)

      wkzSearchStore.plateType = newPlateType
      adjustNumbers()
    }
  })

  const vehicleProxy = computed({
    get () {
      return wkzSearchStore.vehicle
    },
    set (value) {
      wkzSearchesStore.searches = []
      wkzSearchesStore.selectPlate(null, null)

      if (value === 'tractor' || value === 'green-car') {
        wkzSearchStore.vehicle = value
        plateTypeProxy.value = 'green'
        return
      }
  
      const strongVehicleType = getStrongVehicleType(value)
  
      const isConfigurationValid = officeProxy.value?.configurations
        .some(config => config.vehicle === strongVehicleType && config.plateType === plateTypeProxy.value)
  
      const isVehicleInConfiguration = officeProxy.value?.configurations
        .some(config => config.vehicle === strongVehicleType)
  
      if (isVehicleInConfiguration || !officeProxy.value) {
        wkzSearchStore.vehicle = value
      } else {
        wkzSearchStore.vehicle = 'car'
      }
  
      if (!isConfigurationValid) {
        plateTypeProxy.value = 'standard'
      }
  
      adjustNumbers()
    }
  })

  const selectedOfficeIdProxy = computed({
    get () {
      return wkzSearchStore.office?._id
    },
    set (value) {
      if (value) init(value)
    }
  })

  const seasonFromProxy = computed({
    get () {
      return wkzSearchStore.seasonFrom
    },
    set (value) {
      wkzSearchStore.seasonFrom = value
    }
  })

  const seasonToProxy = computed({
    get () {
      return wkzSearchStore.seasonTo
    },
    set (value) {
      wkzSearchStore.seasonTo = value
    }
  })

  const availableVehicleOptions = computed(() => {
    return vehicleOptions.filter(({ value }) => {
      const strongVehicleType = getStrongVehicleType(value)
  
      return officeProxy.value?.configurations
        .some(configuration => configuration.vehicle === strongVehicleType)
    })
  })

  const availablePlateTypeOptions = computed(() => {
    const strongVehicleType = getStrongVehicleType(vehicleProxy.value)
  
    return plateTypeOptions.filter(({ value }) => {
      const strongPlateType = getStrongPlateType(value)
  
      return officeProxy.value?.configurations
        .filter(configuration => configuration.plateType === strongPlateType)
        .some(configuration => configuration.vehicle === strongVehicleType)
    })
  })

  async function init (officeId: string) {
    // set office
    if (officeProxy.value?._id !== officeId) {
      officeProxy.value = await useNuxtApp().$feathers.service('registration-offices').get(officeId)
    }
    if (!officeProxy.value) throw new Error('Office not found')

    // set token
    const isCurrentTokenValidForOffice = officeProxy.value?.plateTokens.includes(tokenProxy.value)
    if(!isCurrentTokenValidForOffice) tokenProxy.value = officeProxy.value?.plateTokens[0]

    // set vehicle
    const vehicleToValidate = getStrongVehicleType(vehicleProxy.value)
    const isVehicleValid = officeProxy.value?.configurations
      .some(configuration => configuration.plateType === plateTypeProxy.value && configuration.vehicle === vehicleToValidate)

    if (!isVehicleValid) {
      const defaultConfiguration = officeProxy.value?.configurations
        .find(configuration => configuration.plateType === plateTypeProxy.value)

      const defaultVehicle = defaultConfiguration?.vehicle || 'car'
      vehicleProxy.value = defaultVehicle
    }

    // set plateType
    const isPlateTypeValid = officeProxy.value?.configurations
      .map(configuration => configuration.plateType)
      .includes(plateTypeProxy.value)
  
    if (!isPlateTypeValid) {
      const defaultPlateType = 'standard'
      plateTypeProxy.value = defaultPlateType
    }

    // set suggestionType
    const isSuggestionTypeValid = officeProxy.value?.suggestionTypes.includes(suggestionTypeProxy.value)

    if (!isSuggestionTypeValid) {
      const defaultSuggestionType = 'all'
      suggestionTypeProxy.value = defaultSuggestionType
    }

    // set largest combination
    adjustNumbers()
  }

  function adjustNumbers () {
    const largestCombination = getLargestCombination({
      token: tokenProxy.value,
      letters: lettersProxy.value,
      numbers: numbersProxy.value,
      plateType: plateTypeProxy.value,
      vehicle: vehicleProxy.value
    }, size.value)

    numbersProxy.value = largestCombination.numbers
  }

  const size = computed(() => getDefaultWeakVehicleSize(vehicleProxy.value))

  const plate = ref({
    token: tokenProxy,
    letters: lettersProxy,
    numbers: numbersProxy,
    plateType: plateTypeProxy,
    seasonFrom: seasonFromProxy,
    seasonTo: seasonToProxy,
    size,
    film: 'standard' as const
  })

  return {
    office: officeProxy,
    token: tokenProxy,
    letters: lettersProxy,
    numbers: numbersProxy,
    size,
    plateType: plateTypeProxy,
    vehicle: vehicleProxy,
    selectedOfficeId: selectedOfficeIdProxy,
    suggestionType: suggestionTypeProxy,
    seasonFrom: seasonFromProxy,
    seasonTo: seasonToProxy,
    availableVehicleOptions,
    availablePlateTypeOptions,
    plate,
    init
  }
}
