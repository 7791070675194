import type { Search } from '../types'
import type { RegistrationOffice } from '@black-bird/types'
import generatePlateSizes from '@black-bird/plate-size-generator'
import validateRules from '@black-bird/rule-engine'

type Permutation = { letters: string, numbers: string }

function isPermutationValid (search: Search, permutation: Permutation, office: RegistrationOffice): boolean {
  try {
    const plateSizes = generatePlateSizes({
      token: search.token,
      letters: permutation.letters,
      numbers: permutation.numbers,
      plateType: getStrongPlateType(search.plateType),
      // edge case for light-motorcycle, because plate-size-generator is configured to only allow 255x and 240x for cars
      // -> every 255x and 240x should be handeled as car
      vehicle: search.vehicle === 'light-motorcycle' ? 'car' : getStrongVehicleType(search.vehicle)
    })

    // @ts-ignore
    if (!plateSizes.includes(search.plate.size)) return false

    validateRules({
      city: search.token,
      letters: permutation.letters,
      numbers: permutation.numbers,
      plateType: getStrongPlateType(search.plateType),
      vehicle: getStrongVehicleType(search.vehicle)
    }, office.rules)

    return true
  } catch (err) {
    return false
  }
}

function setQuestionMarks (search: Search, permutation: Permutation) {
  search.letters = permutation.letters
  search.numbers = permutation.numbers
  search.plate.letters = permutation.letters
  search.plate.numbers = permutation.numbers
}

function testPermutations (search: Search, permutations: Permutation[], office: RegistrationOffice) {
  for (const permutation of permutations) {
    const isValid = isPermutationValid(search, permutation, office)

    if (isValid) return setQuestionMarks(search, permutation)
  }

  throw new Error('Bitte füllen Sie das Kennzeichen vollständig aus.')
}

// * This function manipulates the search it gets as an argument
export function fillQuestionMarks (search: Search, office: RegistrationOffice) {
  // letters and numbers are filled
  if (search.letters && search.numbers) return

  // numbers are empty
  if (search.letters && !search.numbers) {
    const permutations: Permutation[] = [
      { letters: search.letters, numbers: '????' },
      { letters: search.letters, numbers: '???' },
      { letters: search.letters, numbers: '??' },
      { letters: search.letters, numbers: '?' }
    ]

    return testPermutations(search, permutations, office)
  }

  // letters are empty
  if (!search.letters && search.numbers) {
    const permutations: Permutation[] = [
      { letters: '??', numbers: search.numbers },
      { letters: '?', numbers: search.numbers }
    ]

    return testPermutations(search, permutations, office)
  }

  // letters and numbers are empty
  if (!search.letters && !search.numbers) {
    const permutations: Permutation[] = [
      { letters: '??', numbers: '????' },
      { letters: '??', numbers: '???' },
      { letters: '??', numbers: '??' },
      { letters: '??', numbers: '?' },
      { letters: '?', numbers: '????' },
      { letters: '?', numbers: '???' },
      { letters: '?', numbers: '??' },
      { letters: '?', numbers: '?' }
    ]

    return testPermutations(search, permutations, office)
  }
}
