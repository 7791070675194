import { _520x110, _255x130, _220x200 } from '../constants'
import { WeakVehicleType } from '@/types'

export function getDefaultWeakVehicleSize (vehicle: WeakVehicleType) {
  switch (vehicle) {
    case 'car':
    case 'green-car':
    case 'trailer':
    case 'lkw':
      return _520x110
    case 'tractor':
    case 'light-motorcycle':
      return _255x130
    case 'motorcycle':
      return _220x200
    default:
      ((x: never) => {
        throw new Error(`${x} was unhandled!`)
      })(vehicle)
  }
}
