import { VehicleType } from '@black-bird/types'
import { WeakVehicleType } from '../types'

export default function getStrongVehicleType (vehicle: WeakVehicleType | VehicleType): VehicleType {
  switch (vehicle) {
    case 'light-motorcycle':
    case 'motorcycle':
      return 'motorcycle'
    case 'car':
    case 'tractor':
    case 'trailer':
    case 'green-car':
    case 'lkw':
      return 'car'
    default:
      return ((x: never) => x)(vehicle)
  }
}
