import { defineStore } from 'pinia'
import type { Search, CheckAvailabilitySearchResult, GetSuggestionsSearchResult } from '@/types'
import type { RegistrationOffice, SearchAction, PlateData } from '@black-bird/types'
import { refAutoReset } from '@vueuse/core'
import { klona } from 'klona/json'
import ruleEngine from '@black-bird/rule-engine'
import { differenceInHours } from 'date-fns'

export default defineStore('wkz-search-results', () => {
  const searches = ref<Search[]>([])
  const validationError = refAutoReset('', 4000)
  const userSearchId = ref('')
  const selectedPlate = ref<{search: Search | null, plate: Pick<PlateData, 'city' | 'letters' | 'numbers'> | null}>()

  async function search () {
    const { 
      token, letters, numbers, plateType, vehicle, suggestionType,
      selectedOfficeId, plate, office
    } = useWkzSearchConfig()

    selectPlate(null, null)

    const action = getSearchAction(letters.value, numbers.value)

    // don't allow to search for suggestions if office doesn't support it
    if (!office.value?.suggestionTypes.includes(suggestionType.value)) {
      validationError.value = 'Bitte füllen Sie Buchstaben und Zahlen im Kennzeichen aus.'
      return
    }

    const search: Search<any> = klona({
      token: token.value,
      letters: letters.value,
      numbers: numbers.value,
      officeId: selectedOfficeId.value as string,
      vehicle: vehicle.value,
      plateType: plateType.value,
      state: 'pending',
      action,
      ...action === 'getSuggestions' && { suggestionType: suggestionType.value },
      timestamp: new Date().toISOString(),
      result: null,
      plate: plate.value
    })

    // fill questionmarks in letters and numbers
    if (action === 'getSuggestions') {
      try {
        fillQuestionMarks(search, office.value)
      } catch (err: any) {
        validationError.value = err.message
        return
      }
    }

    // check if search violates rules of office
    const ruleViolationError = getRuleViolationError(search, office.value)
    if (ruleViolationError) {
      validationError.value = ruleViolationError.message
      return
    }

    // prevent searches for 1 letter and 1 number
    if (search.letters.length === 1 && search.numbers.length === 1) {
      validationError.value = 'Bei einem Buchstaben muss das Kennzeichen mindestens zwei Ziffern enthalten.'
      return
    }

    // prevent duplicate searches
    const duplicateSearch = findDuplicateSearch(search, searches.value)
    if (duplicateSearch) {
      searches.value = [
        duplicateSearch,
        ...searches.value.filter(s => s.timestamp !== duplicateSearch.timestamp)
      ]
      return
    }

    // remove searches with error state
    searches.value = searches.value.filter(s => s.state !== 'error')

    searches.value.unshift(search)

    // get reactive instance of the current search
    const currentSearch = searches.value.find(s => s.timestamp === search.timestamp)
    if (!currentSearch) return

    try {
      const payload = {
        action: currentSearch.action,
        value: {
          plate: {
            city: currentSearch.token,
            letters: currentSearch.letters,
            numbers: currentSearch.numbers,
          },
          officeId: currentSearch.officeId as string,
          vehicle: getStrongVehicleType(currentSearch.vehicle),
          plateType: getStrongPlateType(currentSearch.plateType),
          ...currentSearch.suggestionType && { type: currentSearch.suggestionType },
          userSearchId: userSearchId.value
        }
      }

      const retry = (await import('async-retry')).default

      const result: CheckAvailabilitySearchResult | GetSuggestionsSearchResult = await retry(
        () => useNuxtApp().$feathers.service('reservation-management').create(payload),
        { retries: 1 }
      )

      currentSearch.result = result
      currentSearch.state = 'success'
    } catch (err: any) {
      currentSearch.state = 'error'
      console.log(err)
    }
  }

  function selectPlate (search: Search | null, plate: Pick<PlateData, 'city' | 'letters' | 'numbers'> | null) {
    selectedPlate.value = { search, plate }
  }

  function removePendingSearches () {
    searches.value = searches.value.filter(search => search.state !== 'pending')
  }

  function removeOldSearches () {
    const maxAgeInHours = 48
  
    function testAge (timestamp: string) {
      const ageInHours = differenceInHours(new Date(), new Date(timestamp))
      
      return ageInHours < maxAgeInHours
    }

    searches.value = searches.value.filter(search => testAge(search.timestamp))
  }

  function setUserSearchId () {
    if (userSearchId.value) return

    userSearchId.value = Math.random().toString(36).toUpperCase().substring(2, 10)
  }

  function removeSearchByTimestamp (timestamp: string = '') {
    searches.value = searches.value.filter(search => search.timestamp !== timestamp)
  }

  const latestSearch = computed(() => searches.value?.[0])

  watch(latestSearch, search => {
    if (!search) return
    if (search.action !== 'checkAvailability') return
    if (!search.result?.available) return

    selectPlate(search, {
      city: search.plate.token,
      letters: search.plate.letters,
      numbers: search.plate.numbers
    })
  }, { deep: true})

  return {
    searches,
    validationError,
    userSearchId,
    search,
    removePendingSearches,
    removeOldSearches,
    setUserSearchId,
    removeSearchByTimestamp,
    selectPlate,
    selectedPlate
  }
}, {
  persist: {
    paths: ['searches', 'userSearchId', 'selectedPlate'],
    storage: persistedState.sessionStorage,
    afterRestore ({ store }) {
      if (process.server) return
      store.setUserSearchId()
      store.removePendingSearches()
      store.removeOldSearches()
      store.$persist()
    }
  }
})

function getSearchAction (letters: string = '', numbers: string = ''): Extract<SearchAction, 'checkAvailability' | 'getSuggestions'> {
  if (
    letters.length === 0
    || letters.includes('?')
    || numbers.length === 0
    || numbers.includes('?')
  ) return 'getSuggestions'

  return 'checkAvailability'
}

function getRuleViolationError (search: Search, office: RegistrationOffice): false | Error {
  try {
    const payload = {
      city: search.token,
      letters: search.letters,
      numbers: search.numbers,
      plateType: search.plateType,
      vehicle: getStrongVehicleType(search.vehicle)
    }

    ruleEngine(payload, office.rules)

    return false
  } catch (err) {
    return err as Error
  }
}

function findDuplicateSearch (search: Search, searches: Search[]): Search | undefined {
  return searches.find(existingSearch => {
    if (
      existingSearch.token === search.token
       && existingSearch.letters === search.letters
       && existingSearch.numbers === search.numbers
       && existingSearch.officeId === search.officeId
       && existingSearch.vehicle === search.vehicle
       && existingSearch.plateType === search.plateType
       && existingSearch.action === search.action
       && existingSearch.suggestionType === search.suggestionType
       && existingSearch.state !== 'error'
    ) return true

    return false
  })
}
